<template>
  <CriaTI />
</template>

<script>
import CriaTI from "@/components/termosIndice/CriaTermoIndice";

export default {
  components: {
    CriaTI
  }
};
</script>
